import Vue from 'vue'
Vue.prototype.$static = {}
Vue.prototype.$icons = {}

/**
 * 
 * how to use static data:
 * 
 .vue template:

 export default {
  data: function(){
		return {}
  },

  //set up static data in template
  static: () => ({
	 static_value: 'Vue is awesome'
  }),

  created () {
	 console.log(this.$statics.static_value); // Vue is awesome
  }
}

 */


export default {
	beforeCreate() {
		//setup component $statics 
		let $static = this.$options.static
		if ($static && typeof ($static) === 'function') {
			this.$static = $static.apply(this)
		} else if ($static && typeof ($static) === 'object') {
			this.$static = $static
		}

	},
	created: function () {

		//injects global $portal reference
		if (this.$options.name == "Portal") {
			this.$portal = this;
		}
		if (this.$parent && this.$parent.$portal) {
			this.$portal = this.$parent.$portal;
		}
	},
	methods: {
		/**
		 * lodash _set function for vue
		 * @param {*} obj 
		 * @param {string} path 
		 * @param {*} value 
		 * @returns object
		 */
		_set(obj, path, value) {
			if (!obj) throw new Error("input obj is required")
			return _set(obj, path, value)
		}
	},
	computed: {
		$locale() {
			return this.$root.locale
		},
		$locales() {
			return this.$root.locales
		}
	}
}




/**
 * lodash _set function for vue
 * @param {*} obj 
 * @param {string} path 
 * @param {*} value 
 * @returns object
 */
function _set(obj, path, value) {


	function getNextKey(part, ref) {
		//array:
		if (isArrayPart(part)) {
			let index = part.match(/\[(.*)\]/)[1]
			//get next index
			if (index == "?") {
				return Array.isArray(ref) ? ref.length : 0
			}
			return index
		}
		//object:
		return part
	}

	function isArrayPart(part) {
		return part.match(/\[(.*)\]/) ? true : false
	}

	function isObject(test) {
		if (typeof test == 'object') {
			return test != null ? test.constructor.name === "Object" : false
		}
		return false

	}

	function getPathParts(path) {
		if (typeof path != 'string' || path.length < 2) return null
		// remove leading dot and split path by "." and "[]" (..= escaped)
		//return path.replace(/^\.?/, "").split(/(?<!\.)\.(?!\.)|(?=\[.)|(?<=\])/)
		//ie refactor
		let ret = []
		path.split(/(\[.*\])/).forEach(item => {
			//remove leading .
			if (item[0] == '.') item = item.substr(1)
			let parts = item.split('.')
			ret = ret.concat(parts)
		})
		return ret.filter(item => item !== '')
	}

	function getChildObjectType(part) {
		return isArrayPart(part) ? "array" : "object"
	}
	function isDefined(ref, objectType) {
		if (isObject(ref) && objectType == "object") return true
		if (Array.isArray(ref) && objectType == "array") return true
		return false
	}

	// remove leading dot and split path by "." and "[]"
	let pathParts = getPathParts(path)
	let ref = obj;

	pathParts.reduce((ref, part, i, parts) => {
		let key = getNextKey(part, ref)

		//assign value
		if (i == parts.length - 1) {
			//remove escaped dots in key
			key = key.replace("..", ".")
			Vue.set(ref, key, value)
			return
		}

		let childObjectType = getChildObjectType(parts[i + 1])
		//reference child object
		if (isDefined(ref[key], childObjectType)) {
			ref = ref[key]
			return ref
		}

		//create ArrayObject
		if (childObjectType == 'array') {
			Vue.set(ref, key, [])
			return ref = ref[key]
		}
		//create Object
		if (childObjectType == "object") {
			Vue.set(ref, key, {})
			return ref = ref[key]
		}

	}, ref)

	return obj
}
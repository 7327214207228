<template>
   <div class="ProjectBilling">
      <div class="projectName">{{ service.projectName }}</div>
      <Textarea v-if="showTextArea"
         placeholder="Bemerkungen"
         v-model="service.billingText"
         @input="$emit('update')" />
   </div>
</template>

<script>
import Textarea from '@components/Form/Textarea.vue'

export default {
   name: 'ProjectBilling',
   components: { Textarea },
   props: {
      service: { required: true },
      mode: { required: false, default: 'view' }
   },
   data() {
      return {}
   },
   methods: {

   },
   computed: {
      showTextArea() {
         if (this.mode == 'edit') return true
         return this.service.billingText ? true : false
      }
   },

}
</script>

<style scoped>
.ProjectBilling {
   display: inline-flex;
   flex-direction: column;
   row-gap: 10px;

}

.projectName {
   font-size: 18px;
   font-weight: 500;
   padding: 3px 0;
   border-bottom: 1px solid grey;

}

.Textarea {
   font-size: 18px;
}
</style>
<template>
   <div class="TaskBilling">
      <div v-if="noData"
         class="noData">noch nichts zum verrechnen..</div>
      <div v-else
         class="body">
         <div class="taskName">{{ service.name }}</div>
         <Textarea v-if="showTextArea"
            :readonly="readonly"
            placeholder="Bemerkungen"
            v-model="service.billingText"
            @input="$emit('update')" />
         <div class="grid"
            :style="gridStyle">
            <div v-for="(record, index) in services"
               :key="index"
               :class="{ notBillable: record.notBillable }"
               class="gridRow">
               <template v-for="column in gridColumns">
                  <div v-if="column.id == 'date'"
                     :key="column.id"
                     class="date">
                     {{ getEuDate(record.date) }}
                  </div>
                  <div v-if="column.id == 'text'"
                     :key="column.id"
                     class="text">{{ record.text }}</div>
                  <div v-if="column.id == 'fromTo'"
                     :key="column.id"
                     class="fromTo">
                     <span>{{ record.from }}</span>
                     <span>-</span>
                     <span>{{ record.to }}</span>
                  </div>
                  <div v-if="column.id == 'minutes'"
                     :key="column.id"
                     class="minutes">
                     {{ toDuration(record.minutes) }} Std.
                  </div>
                  <div v-if="column.id == 'employee'"
                     :key="column.id"
                     class="employee">{{ record.employee }}</div>
                  <div v-if="column.id == 'hourlyRate'"
                     :key="column.id"
                     class="hourlyRate">{{ toCHF(record.hourlyRate) }}/Std.</div>
                  <div v-if="column.id == 'value'"
                     :key="column.id"
                     class="value">{{ toValue(record.value) }}</div>
               </template>

            </div>
         </div>
      </div>
   </div>
</template>

<script>

import InputTime from '@components/Form/InputTime.vue';
import Switcher from '@components/Form/Switcher.vue';

import Textarea from '@components/Form/Textarea.vue';
import { minutes } from 'src/utils/utils'
import { Day } from '@SyoLab/date-time'
export default {
   name: 'TaskBilling',
   components: {
      InputTime,
      Switcher,
      Textarea
   },
   props: {
      service: { required: true, type: Object },
      mode: { required: false, default: 'view' },
      readonly: { required: false, default: false }
   },
   data() {
      return {
      }
   },
   methods: {
      toCHF(value) {
         if (!value) return '--'
         return Number(value).toFixed(2)
      },
      toValue(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      toDuration(value) {
         if (!value) return null
         return minutes.toFormatDecimal(value).toFixed(2)
      },
      getEuDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
   },
   computed: {
      services() {
         return this.service.services
      },
      settings() {
         return this.service.settings
      },
      gridColumns() {
         let columns = []
         columns.push({ id: 'text', title: 'Text', width: '1fr' })
         if (this.settings.showDate) columns.push({ id: 'date', title: 'Datum', width: 'max-content' })
         if (this.settings.showEmployee) columns.push({ id: 'employee', title: 'Mitarbeiter', width: 'max-content' })
         if (this.settings.showFromTo) columns.push({ id: 'fromTo', title: 'Beginn', width: 'max-content' })
         if (this.settings.showDuration) columns.push({ id: 'minutes', title: 'Dauer', width: 'max-content' })
         if (this.settings.showHourlyRate) columns.push({ id: 'hourlyRate', title: 'Stundensatz', width: 'max-content' })
         columns.push({ id: 'value', title: 'Betrag', width: 'max-content' })

         return columns
      },
      gridStyle() {
         return {
            gridTemplateColumns: this.gridColumns.map(column => `[${column.id}] ${column.width}`).join(' ')
         }
      },
      noData() {
         return this.services.length == 0
      },
      showTextArea() {
         if (this.mode == 'edit') return true
         return this.service.billingText ? true : false
      }
   },
}
</script>

<style scoped>
.TaskBilling {
   display: inline-flex;
   flex-direction: column;
   row-gap: 10px;
   padding: 15px 0;
}

.TaskBilling>.title {
   font-size: 22px;
   font-weight: 300;
}

.Textarea {
   height: 22px;
   padding-left: 0;
   font-size: 18px;
}


.body {
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}

.body .taskName {
   font-weight: 500;
   line-height: 20px;
}

.gridRow {
   display: contents;
   font-size: 18px;
}


.grid {
   display: grid;
   width: 100%;
}

.gridRow>* {
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   min-height: 24px;
   padding: 3px 5px;
}

.gridRow.notBillable,
.gridRow.notBillable /deep/ input {
   color: #a9a8a8 !important
}

.grid .date {
   grid-column: date;
   padding-left: 5px;
   padding-right: 5px;
   justify-content: flex-end;
}

.grid .text {
   grid-column: text;
   min-width: 195px;
   padding-right: 10px;
   padding-left: 0;
}

.grid .fromTo {
   grid-column: fromTo;
   column-gap: 3px;
   width: 105px;
   justify-content: center
}

.grid .dash {
   grid-column: dash;
   justify-content: center;
}

.grid .employee {
   grid-column: employee;
   padding: 3px;
}

.grid .minutes {
   grid-column: minutes;
   width: 110px;
   justify-content: flex-end;
}

.grid .hourlyRate {
   grid-column: hourlyRate;
   width: 110px;
   justify-content: flex-end
}

.grid .value {
   grid-column: value;
   width: 85px;
   justify-content: flex-end;
   padding-right: 0;
}

.noData {
   color: #a6a6a6;
}
</style>
<template>
	<textarea rows="1"
		class="Textarea"
		:class="{ focus: hasFocus }"
		:value="value"
		:placeholder="settings.placeholder"
		:readonly="settings.readonly"
		@input="onTextareaChange"
		@focus="onFocus"
		@blur="onBlur"
		@keydown.enter="keyDownEnter" />
</template>

<script>
import utils from '@SyoLab/utils'
// auto-grow textarea
export default {
	name: 'Textarea',
	components: {},
	props: {
		value: { required: true },
		placeholder: { required: false },
		options: { required: false },
		onEnter: { required: false },
		readonly: { required: false },
	},

	data() {
		return { hasFocus: false, textAreaValue: null }
	},
	methods: {
		async onTextareaChange(e) {
			this.setHeight()
			if (e) {
				if (this.settings.throttled) {
					this.throttledEmit(e.target.value)
				} else {
					this.emit(e.target.value)
				}
			}

		},
		setHeight(height) {
			if (!height) {
				this.$el.style.height = 'auto'
				height = this.$el.scrollHeight
			}

			this.$el.style.height = height + 'px'
		},
		throttledEmit: utils.throttle(
			function (value) {
				this.emit(value)
			},
			200,
			{ leading: false, trailing: true },
		),
		emit(value) {
			this.$emit('input', value)
		},
		focus() {
			this.$el.focus()
		},
		onFocus() {
			this.hasFocus = true
			this.$emit('focus', this.$el.value)
		},
		onBlur() {
			this.hasFocus = false
			this.$emit('blur', this.$el.value)
		},
		keyDownEnter(e) {
			if (this.onEnter && typeof this.onEnter === 'function') this.onEnter(e)
		}
	},
	computed: {
		settings() {
			return {
				throttled: true,
				placeholder: this.placeholder || null,
				readonly: this.readonly || false,
				...(this.options || {}),
			}
		},
	},
	watch: {
		value: {
			immediate: false,
			handler: function (value) {
				if (!value) {
					this.$el.value = ''
				}
				setTimeout(() => {
					this.setHeight()
				}, 0)
			},
		},
	},
	mounted() {
		this.setHeight()
	},
}
</script>

<style scoped>
.Textarea {
	border: 0;
	outline: none;
	overflow: hidden;
	resize: none;
	background-color: transparent;
	display: block;
	min-height: 22px;
}
</style>

<template>
	<button class="Button form"
		:class="{
			primary: isPrimary,
			loading: settings.loading,
			disabled: !settings.enabled,
		}"
		:tabindex="settings.tabindex"
		@click="onClick"
		@keyup.enter="onClick"
		@keydown.tab.prevent="onTab">
		<Icon v-if="settings.loading"
			class="spinner"
			:icon="icons.Sync"
			spin />
		<div class="label">
			<slot>{{ settings.text }}</slot>
		</div>
	</button>
</template>

<script type="text/javascript">
import Icon from '@icons/Icon.vue'
import { Sync } from '@icons/appFabric/icons.js'
export default {
	name: 'Button',
	components: { Icon },
	props: {
		text: {
			type: String,
			required: false,
			default: undefined,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		primary: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		options: {
			type: Object,
			required: false,
			default: () => {
				return {}
			},
		},
		enabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		tabindex: { required: false },
	},
	data() {
		return {
			icons: { Sync },
		}
	},
	computed: {
		settings() {
			let ret = {
				loading: this.loading,
				text: this.text,
				primary: this.primary,
				tabindex: this.tabindex,
				enabled: this.enabled,
				...this.options,
			}
			return ret
		},
		isPrimary() {
			if (this.settings.primary !== false) return true
			return false
		},
	},
	methods: {
		onClick(e) {
			if (this.$el.classList.contains('disabled')) return
			if (!this.settings.loading && this.settings.enabled) {
				this.$emit('click', e)
			} else {
				this.$emit('cancel')
			}
		},
		focus() {
			if (!this.settings.enabled || this.settings.loading) return
			this.$el.focus()
		},
		onTab(e) {
			this.$emit('tab', e.shiftKey)
		},
	},
}
</script>

<style scoped>
.Button {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	vertical-align: top;
	padding-top: 0px;
	padding-right: 16px;
	padding-bottom: 0px;
	padding-left: 16px;
	min-width: 80px;
	height: 32px;
	background-color: rgb(255, 255, 255);
	color: rgb(50, 49, 48);
	user-select: none;
	outline: transparent;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(138, 136, 134);
	border-image: initial;
	text-decoration: none;
	border-radius: 2px;
	user-select: none;
}

.Button.m3 {
	border-radius: 16px;
}

.Button.blue:focus {
	background-color: var(--activeHover);
}

.Button.disabled {
	cursor: default !important;
	color: rgb(185 185 185) !important;
	border-color: rgb(185 185 185) !important;
	background-color: white !important;
}

.Button.outline {
	background-color: transparent;
}

.Button.loading {
	cursor: default;
}

.Button:hover {
	background-color: rgb(243, 242, 241);
	color: rgb(32, 31, 30);
}

/* red */
.Button.red {
	border-color: #e73838;
	color: #e73838;
}

.Button.red.solid {
	background-color: #e73838;
	color: white;
}

.Button.red:hover {
	color: #e73838;
	background-color: #ffe7e7;
}

.Button.red.solid:hover {
	color: white;
	background-color: #e27272;
	border-color: #e27272;
}

/* blue */
.Button.blue {
	border-color: var(--accentBlue);
	color: var(--accentBlue);
}

.Button.blue.solid {
	background-color: var(--accentBlue);
	color: white;
}

.Button.blue:hover {
	color: var(--accentBlue);
	background-color: #eaeff5;
}

.Button.blue.solid:hover {
	color: white;
	background-color: #569ee6;
	border-color: #569ee6;
}

/* primary */
.Button.primary {
	background-color: var(--primary-btn-color);
	color: var(--primary-btn-fontColor);
	border-color: var(--primary-btn-color);
}

Button.primary:hover {}

.label {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
	display: flex;
}

.spinner {
	margin-right: 7px;
}
</style>

<template>
	<div class="InputTime"
		:class="{ readonly: hoursReadonly && minutesReadonly, focus: hasFocus }">
		<label v-if="label">{{ label }}</label>
		<div class="body">
			<div class="timeIcon"
				v-if="settings.icon"
				@click="$emit('iconClick')">
				<div class="arc"></div>
				<div class="arrow"></div>
				<div class="line"></div>
				<Icon :icon="iconClock" />
			</div>
			<div class="input hours"
				:style="hrWidth">
				<div class="placeholder"
					v-if="!hrValue">
					<div class="dash"></div>
					<div class="dash"></div>
				</div>
				<input class="timeInput hrInput"
					ref="hrInput"
					:value="hrValue"
					:readonly="hoursReadonly"
					:tabindex="tabindex"
					@keydown="onHrKeyDown"
					@keyup.enter="$emit('enter', 'hr')"
					@focus="onHrFocus"
					@input="onHrInput"
					@blur="onHrBlur" />
			</div>
			<span class="divider">:</span>
			<div class="input minutes">
				<div class="placeholder"
					v-if="!minValue">
					<div class="dash"></div>
					<div class="dash"></div>
				</div>
				<input class="timeInput minInput"
					ref="minInput"
					:value="minValue"
					:readonly="minutesReadonly"
					:tabindex="tabindex"
					@keydown="onMinKeyDown"
					@input="onMinInput"
					@focus="onMinFocus"
					@keyup.enter="onMinEnter"
					@blur="onMinBlur" />
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@icons/Icon.vue'
import { Clock } from '@icons/appFabric/icons'

export default {
	name: 'InputTime',
	components: { Icon },
	props: {
		readonly: {
			type: [String, Boolean, Object],
			required: false,
		},
		value: { required: true },
		duration: { required: false },
		hrPadding: { required: false, default: true },
		tabindex: { required: false, default: 0 },
		icon: { required: false },
		options: { required: false },
		label: { required: false },
	},
	data() {
		return {
			iconClock: Clock,
			hrValue: '',
			minValue: '',
			lastHrCursorPos: 0,
			prefix: 'pos',
			hasFocus: false,
		}
	},
	methods: {
		focus() {
			this.$refs.hrInput.focus()
		},
		onHrFocus(event) {
			event.target.setSelectionRange(0, event.target.value.length)
			this.lastHrCursorPos = null
			this.hasFocus = true
		},
		onMinFocus(event) {
			event.target.setSelectionRange(0, 2)
			this.hasFocus = true
		},
		onHrBlur(event) {
			this.hasFocus = false
			this.$emit('blur', 'hr')
		},
		onMinBlur(event) {
			this.hasFocus = false
			this.$emit('blur', 'min')
		},
		setFocus(el) {
			el.focus()
			el.setSelectionRange(0, 2)
		},
		onHrKeyDown(event) {
			let key = event.key

			if (this.hoursReadonly) {
				if (key == 'Tab') return
				event.preventDefault()
				return
			}

			var cursorPos = this.$refs.hrInput.selectionEnd
			let selectionStart = Math.min(this.$refs.hrInput.selectionStart, this.$refs.hrInput.selectionEnd)
			let selectionEnd = Math.max(this.$refs.hrInput.selectionStart, this.$refs.hrInput.selectionEnd)
			if (key == 'Backspace') {
				return
			}
			if (key == 'Delete') {
				return
			}
			if (key == 'Tab') {
				return
			}
			if (key == 'Alt') {
				return
			}
			if (key == 'ArrowLeft') {
				return
			}
			if (key == 'ArrowRight') {
				let inputValue = this.$refs.hrInput.value
				if (this.$refs.hrInput.selectionEnd == inputValue.length) {
					event.preventDefault()
					this.setFocus(this.$refs.minInput)
				}
				return
			}

			if (isNaN(key) && key != '-') {
				event.preventDefault()
				return
			}

			if (key == '-' && this.settings.duration) {
				let inputValue = this.$refs.hrInput.value
				if (inputValue.length > 0) {
					// do not allow is selection is in between number
					if (selectionStart > 0) {
						event.preventDefault()
						return
					}
				}
			}

			let inputValue = this.clearSelection(this.hrValue, this.$refs.hrInput) // returns selection value
			let cursorIsAtEnd = !inputValue || inputValue.length == cursorPos
			// insert key to inputValue where cursor ist
			let newValue = this.getNewValueStringHr(inputValue, this.$refs.hrInput, key)

			// lenght of hrs depending on duration or hr
			let valueLength = this.settings.duration ? 4 : 2
			// clip to last 2 char
			if (newValue.length > valueLength) newValue = newValue.substr(1)

			// limit to 24 / 999
			let limit = this.settings.duration ? 999 : 24

			if (Number(newValue) > limit) {
				event.preventDefault()
				return
			}
			this.hrValue = newValue

			if (newValue.length == valueLength && this.lastHrCursorPos >= 1) {
				this.setFocus(this.$refs.minInput)
			}

			this.lastHrCursorPos = cursorPos + 1
			event.preventDefault()
		},
		onHrInput(event) {
			// get called on delete or backspace
			this.hrValue = event.target.value
		},
		onMinInput(event) {
			if (!event.isTrusted) return // avoid infinite loop
			let val = event.target.value
			if (val.length == 3) {
				this.minValue = val.substr(1)
			} else {
				this.minValue = val
			}
		},
		onMinEnter() {
			setTimeout(() => {
				// let outValue emit new value, then enter
				this.$emit('enter', 'min')
			}, 1)
		},
		onMinKeyDown(event) {
			let key = event.key

			if (this.minutesReadonly) {
				if (key == 'Tab') return
				event.preventDefault()
				return
			}

			var cursorPos = this.$refs.minInput.selectionEnd

			if (key == 'Backspace') {
				return
			}
			if (key == 'Delete') {
				return
			}
			if (key == 'Tab' || key == 'Enter') {
				this.minValue = this.padMin(this.minValue)
				return
			}
			if (key == 'ArrowLeft') {
				if (cursorPos === 0) {
					event.preventDefault()
					this.minValue = this.padMin(this.minValue)
					this.$refs.hrInput.focus()
				}
				return
			}
			if (key == 'ArrowRight') {
				return
			}

			if (isNaN(key)) {
				event.preventDefault()
				return
			}

			this.minValue = this.clearSelection(this.minValue, this.$refs.minInput)

			let newValueString = this.getNewValueStringMin(this.minValue, this.$refs.minInput, key)

			if (newValueString.length > 2) {
				newValueString = newValueString.substr(1)
			}
			if (Number(newValueString) > 60) {
				event.preventDefault()
				return
			}
		},
		getNewValueStringHr(currentValue, input, key) {
			let cursorPos = input.selectionEnd
			let isAtEnd = currentValue.length - 1 == cursorPos
			let prepend = ''
			let append = ''

			let newValue
			if (cursorPos > 0) {
				// fill where cursor is
				prepend = currentValue.substring(0, cursorPos)
				append = currentValue.substring(cursorPos)
				newValue = prepend + key + append
			} else {
				// prepend

				newValue = key + currentValue
			}

			newValue = this.padHr(newValue)
			return newValue
		},
		getNewValueStringMin(currentValue, input, key) {
			let cursorPos = input.selectionEnd
			let isAtEnd = currentValue.length - 1 == cursorPos
			let prepend = ''
			let append = ''
			if (cursorPos > 0) {
				// fill where cursor is
				prepend = currentValue.substring(0, cursorPos)
				append = currentValue.substring(cursorPos)
				return prepend + key + append
			} else {
				// prepend
				return key + currentValue
			}
		},
		clearSelection(value, input) {
			// delete selection
			if (value && input.selectionEnd != input.selectionStart) {
				value = value.slice(0, input.selectionStart) + value.slice(input.selectionEnd)
			}
			// return selection value
			return value
		},
		getHr(hhmm) {
			if (!hhmm) return null
			let hr = String(hhmm).split(':')[0]
			if (hr == '-') return hr
			return parseInt(hr)
		},
		getMin(hhmm, toInt) {
			if (!hhmm) return null
			let min = String(hhmm).split(':')[1]
			return toInt ? parseInt(min) : min
		},
		padHr(hrValue) {
			hrValue = String(hrValue)
			let pad = this.hrPadding ? '0' : ' '
			let paddingLength = this.settings.duration ? 1 : 2
			if (this.settings.duration && hrValue.length > 0) {
				// exceptions
				if (hrValue == '-' || hrValue == '-0') {
					// we do not update if one of these is already set
					if (this.hrValue == '-' || this.hrValue == '-0') return this.hrValue
					return hrValue
				}
			}

			let paddedValue = String(parseInt(Number(hrValue))).padStart(paddingLength, pad)
			return paddedValue
		},
		padMin(minValue) {
			return String(minValue).trim().padStart(2, '0')
		},
		displayValue(value) {
			if (value) {
				let values = value.split(':')
				if (values.length == 1) {
					this.hrValue = ''
					this.minValue = this.padMin(values[0])
				} else {
					this.hrValue = this.padHr(values[0])
					this.minValue = this.padMin(values[1])
				}
			} else {
				// no value set, just pad
				this.minValue = this.padMin('')
				this.hrValue = this.padHr('')
			}
		}
	},
	computed: {
		outValue() {
			if (this.hrValue || this.minValue) {
				return `${this.hrValue}:${this.minValue}`
			}
			return null
		},
		hoursReadonly() {
			if (this.readonly === true) return true
			if (this.readonly === 'true') return true
			if (typeof this.readonly == 'object') {
				if (typeof this.readonly.hours == 'undefined') return false
				if (typeof this.readonly.hours == 'boolean') return this.readonly.hours
				return true
			}
			return false
		},
		minutesReadonly() {
			if (this.readonly === true) return true
			if (this.readonly === 'true') return true
			if (typeof this.readonly == 'object') {
				if (typeof this.readonly.minutes == 'undefined') return false
				if (typeof this.readonly.minutes == 'boolean') return this.readonly.minutes
				return true
			}
			return false
		},
		settings() {
			return {
				icon: this.icon === false ? false : true,
				duration: this.duration ? true : false,
				...(this.options ? this.options : {}),
			}
		},
		hrWidth() {
			if (this.settings.duration) {
				if (!this.hrValue || this.hrValue.length <= 1) return { width: '20px' }
				if (this.hrValue.length <= 2) return { width: '23px' }
				if (this.hrValue.length <= 3) return { width: '30px' }
				return { width: '40px' } //'40px'
			}
			return { width: '22px' }
		},

	},
	watch: {
		outValue(value) {
			if (!this.value) {
				// if value is not defined and outvalue == 0 => do not update
				if (this.getHr(value) == 0 && this.getMin(value, true) == 0) {
					return
				}
			}

			if (this.getHr(value) == this.getHr(this.value)) {
				if (this.getMin(value, true) == this.getMin(this.value, true)) {
					return
				}
			}

			// empty minute value => do not emit update
			let minValue = this.getMin(value, true)
			if (minValue !== 0) {
				if (!minValue) return
			}
			// if min value.length < 2 => do not emit update
			if (String(this.getMin(value)).length < 2) return
			this.$emit('input', this.outValue)
		},
		value: {
			immediate: true,
			handler: function (value) {
				this.displayValue(value)
			},
		},
		readonly: {
			immediate: true,
			handler: function (value) {
				if (typeof this.readonly == 'object') {
					if (typeof this.readonly.minutes == 'string' || typeof this.readonly.minutes == 'number') {
						this.minValue = this.padMin(this.readonly.minutes)
					}
					if (typeof this.readonly.hours == 'string' || typeof this.readonly.hours == 'number') {
						this.hrValue = this.padHr(this.readonly.hours)
					}
				}
				return false
			},
		},
		hasFocus(value) {
			// if focus is lost, resync value to display value
			setTimeout(() => {
				if (!value && this.outValue !== this.value) {
					// resync value to display value
					this.displayValue(this.value)
				}
			}, 10)

		},
	},
}
</script>

<style style scoped>
.InputTime {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 4px;
	position: relative;
}

.InputTime.slim {
	padding-bottom: 0;
}

.InputTime::before {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	border-style: solid;
	border-width: 1px 0 0 0;
	border-color: #619cca;
	z-index: 1;
	white-space: nowrap;
}

.InputTime::after {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	transform: scaleX(0);
	border-style: solid;
	border-width: 1px 0 1px 0;
	border-color: #619cca;
}

.InputTime.readonly::after,
.InputTime.readonly::before {
	border-color: rgba(0, 0, 0, 0.42);
}

.InputTime.readonly .placeholder.dash {
	border-color: rgba(0, 0, 0, 0.42);
}

.InputTime.readonly .timeIcon {
	color: rgba(0, 0, 0, 0.42);
}

.InputTime.focus::after {
	transform: scaleX(1);
}

.InputTime.slim .timeIcon {
	display: none;
}

.InputTime.slim::before,
.InputTime.slim::after {
	content: unset;
}

.InputTime>.body {
	display: flex;
	align-items: center;
	margin-bottom: 2px;
}

.InputTime.slim>.body {
	margin-bottom: 0;
}

label {
	margin-bottom: 10px;
}

.input {
	position: relative;
	width: 22px;
	height: 20px;
}

input {
	outline: none;
	border: none;
	position: absolute;
	color: var(--accentBlue);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: transparent;
}

input.hrInput {
	text-align: end;
}

input:read-only {
	color: black;
}

.InputTime.input.hours {
	margin-left: 3px;
}

.InputTime.to.input.hours {
	margin-left: 7px;
}

.placeholder {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space - around;
	height: 100%;
	width: 100%;
}

.placeholder.dash {
	border-top: 1px solid var(--accentBlue);
	width: 7px;
	margin-top: 3px;
}

.InputTime.readonly.placeholder.dash {
	border-top-color: #afafaf;
}

.InputTime.readonly.timeIcon {
	color: #afafaf;
}

.InputTime.divider {
	color: var(--accentBlue);
}

.InputTime.readonly.divider {
	color: #afafaf;
}

.timeIcon.arc,
.timeIcon.arrow,
.timeIcon.line {
	display: none;
}

.InputTime .timeIcon {
	font-size: 17px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	width: 16px;
	margin-top: -2px;
	color: var(--accentBlue);
}

.InputTime.timeIcon .Icon {
	margin-bottom: 2px;
}

.InputTime.readonly.timeIcon.arc {
	border-top-color: #afafaf !important;
}

.InputTime.from.timeIcon.arc {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: 1px solid transparent;
	border-top-color: #323130;
	transform: rotate(-45deg);
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}

.InputTime.readonly.from.timeIcon.arrow {
	border-top-color: #afafaf !important;
	border-right-color: #afafaf !important;
}

.InputTime.from.timeIcon.arrow {
	display: block;
	width: 5px;
	height: 5px;
	border-top: 1px solid #323130;
	border-right: 1px solid #323130;
	position: absolute;
	top: -2px;
	left: 9px;
	transform: rotate(45deg);
}

.InputTime.readonly.timeIcon.line {
	border-bottom-color: #afafaf !important;
}

.InputTime.from.timeIcon.line {
	display: block;
	width: 6px;
	height: 14px;
	border-bottom: 1px solid #323130;
	position: absolute;
	top: 0;
	left: 0;
}

.InputTime.to.timeIcon.arc {
	display: block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid transparent;
	border-top-color: #323130;
	transform: rotate(45deg);
	position: absolute;
	top: -2px;
	left: 2px;
}

.InputTime.readonly.to.timeIcon.arrow {
	border-bottom-color: #afafaf !important;
	border-right-color: #afafaf !important;
}

.InputTime.to.timeIcon.arrow {
	display: block;
	width: 6px;
	height: 6px;
	border-right: 1px solid #323130;
	border-bottom: 1px solid #323130;
	position: absolute;
	top: 5px;
	right: -3px;
	transform: rotate(45deg);
}

.InputTime.to.timeIcon.line {
	display: block;
	width: 6px;
	height: 14px;
	border-bottom: 1px solid #323130;
	position: absolute;
	top: 0;
	right: 0;
}

.prefix {
	display: inline - flex;
	align-items: center;
}
</style>

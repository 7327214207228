
import eventBus from "./eventBus";
import utils from "@SyoLab/utils"

let portalState






async function getResponseBody(response, fetchSettings = {}) {

	// download
	if (fetchSettings.download) {
		let contentDisposition = response.headers.get('Content-Disposition')
		let filename
		if (!contentDisposition) {
			console.warn('no Content-Disposition header set')
		} else {
			filename = contentDisposition.split('=').pop()
		}
		response.filename = filename
		return await response.blob()
	}
	// blob
	if (fetchSettings.blob) {
		return await response.blob()
	}

	let contentType = response.headers.get("Content-Type");

	// object URL
	if (fetchSettings.objectURL) {
		let blob = await response.blob()
		return URL.createObjectURL(blob)
	}

	// json
	if (contentType && contentType.includes("application/json")) {
		return await response.json()
	} else {
		return null
	}
}

function getRequestBody(data) {

	let ret = {
		body: data || null,
		contentType: null
	}

	if (data && typeof data == "object") {
		// FormData
		if (data instanceof FormData) return ret
		// Json
		ret.body = JSON.stringify(data)
		ret.contentType = 'application/json;charset=utf-8'
		return ret
	}

	if (data && typeof data == "string") {
		ret.contentType = "text/plain"
		return ret
	}

	return ret
}


class FetchError extends Error {
	constructor(response) {
		let message
		if (typeof response == 'object' && response !== null) {
			message = response.data?.message || response.statusText
		} else {
			message = response
		}


		super(message);
		if (typeof response == 'object' && response !== null) {
			this.url = response.url;
			this.status = response.status;
			this.name = response.statusText;
			this.data = response.data
		}

	}
}



var Portal;


export default new class {
	accessJWT = {
		token: undefined,
		expires: undefined,
		hasExpired: function () {
			if (!this.token) return true
			return this.expires < new Date()
		}
	}
	authTTL = undefined
	userId = undefined

	constructor() {

		//set events
		eventBus.on("auth.logoff", (noFetch) => {
			this.logoff(noFetch)
		})

		window.removeEventListener('storage', this.onStorageChange)
		window.addEventListener('storage', this.onStorageChange.bind(this));
	}

	setPortalRef(portalRef) {
		Portal = portalRef
	}
	setPortalState(portalStateRef) {
		portalState = portalStateRef
	}

	onStorageChange(e) {
		if (e.key == 'userId' && e.newValue != this.userId) {
			eventBus.emit('auth.logoff', true)
		}
	}

	getApiBasePath(options) {
		let settings = Portal?.portalConfig.api
		return options?.baseUrl || settings?.baseUrl;
	}

	async fetch(path, options = {},) {

		let fetchID = utils.shortId()
		let settings = Portal?.portalConfig.api
		let url = this.getApiBasePath(options) + path;

		//default fetch options
		let fetchSettings = {
			method: "GET", //default is GET
			headers: {},
			credentials: 'include',
			authorize: settings?.auth === false ? false : true //authorize before request
		};


		//assign options, stringify data
		Object.keys(options).forEach(key => {
			if (key == "data") {
				// set data and content-type
				let { body, contentType } = getRequestBody(options.data)
				fetchSettings.body = body
				if (contentType) fetchSettings.headers['Content-Type'] = contentType
				return
			} else {
				fetchSettings[key] = options[key]
			}

		})

		//start fetch
		if (portalState && options.showProgress !== false) {
			portalState.progressStart(fetchID)
		}


		//authorize
		if (fetchSettings.authorize) {
			if (await this.authorize()) {
				fetchSettings.headers.Authorization = "Bearer " + this.accessJWT.token
			} else {
				throw new FetchError({
					statusText: "AuthorizationError",
					status: -1,
				})
			}
		}

		let response
		//console.log('fetchSettings', fetchSettings)
		//fetch
		try {

			response = await fetch(url, fetchSettings);
			response.data = await getResponseBody(response, fetchSettings)
			if (portalState && options.showProgress !== false) {
				setTimeout(() => {
					portalState.progressStop(fetchID)
				}, 500)

			}

		} catch (err) {
			//just network error are catched here:
			if (options.showProgress !== false) {
				setTimeout(() => {
					portalState.progressStop(fetchID)
				}, 500)
			}

			//notify
			eventBus.emit('notify.error', "Verbindungsfehler", {
				body: "Keine Verbindung mit dem Internet",
				timeout: 10000
			})
			//throw error
			throw new FetchError({
				statusText: "NetworkError",
				status: -1,
				data: { message: err.message }
			})
		}

		//all other errors are catched here
		if (!response.ok) {

			//401
			if (response.status == 401) {
				console.warn('unauthorized')
			} else if (response.status > 401) {
				//notify server errors
				if (options.notify !== false) {
					eventBus.emit('notify.error', `Serverfehler ${response.statusText}`, { timeout: 3000 })
				}
			} else {
				console.warn('fetch error', response)
			}

			//throw error
			throw new FetchError(response)

		}
		return response

	}

	async post(path, data, options = {}) {

		return this.fetch(path, {
			method: "POST",
			...options,
			data: data,
		})
	}

	async get(path, options = {}) {
		return await this.fetch(path, {
			method: "GET",
			...options,
		})
	}

	async delete(path, data, options = {}) {

		return await this.fetch(path, {
			method: "DELETE",
			...options,
			data: data,
		})
	}

	async put(path, data = {}, options = {}) {

		return await this.fetch(path, {
			method: "PUT",
			...options,
			data: data
		})
	}

	async patch(path, data = {}, options = {}) {

		return await this.fetch(path, {
			method: "PATCH",
			...options,
			data: data
		})
	}

	async serverBuild() {

		try {
			let response = await fetch('/api/portal/version', { cache: "no-store" })

			if (response.ok) {
				response.data = await getResponseBody(response)
			}

			return response.data

		} catch (error) {
			console.error('serverBuild error:', error)
			return {}
		}

	}

	async authorize() {

		//if not accessJWT ist set => do not try to renew
		//if (!this.accessJWT.token) return false
		//if accessJWT has not expired => authorized
		if (this.accessJWT.hasExpired() == false) {
			return true
		}

		// try to renew
		try {
			await this.authRenew()
			return true
		} catch (error) {
			return false

		}

	}

	async authRenew() {

		//try to renew accessJWT through CLID and session
		try {
			var response = await this.fetch("auth/renew", {
				method: "GET",
				authorize: false
			})

			let loginResponse = response.data
			this.onLoginSuccess(loginResponse)

		} catch (error) {
			portalState.authenticated = false
			portalState.ready = true
		}
	}

	async login(userId, password) {

		this.userId = userId

		let response = await this.fetch("auth/login", {
			method: "POST",
			data: {
				userId: userId,
				password: password
			},
			authorize: false
		})

		//if no error => do on LoginSuccess
		let loginResponse = response.data
		//this.onLoginSuccess(loginResponse)
		return loginResponse
	}

	onLoginSuccess(response) {

		this.setAccessToken(response.accessJWT)
		//set authorization timeout
		this.setAuthTTL(portalState.ttl)
		portalState.onLoginSuccess(response)

		// if stayLoggedIn is not activated: setTimeout
		if (!portalState.stayLoggedIn) {

			let delay = portalState.ttl * 60 * 1000 - 10000
			setTimeout(() => {
				if (portalState.stayLoggedIn) return
				let now = new Date()
				//check if auth ttl is still the set value
				if (!this.authTTL || !isValidDate(this.authTTL) || this.authTTL < now) {
					portalState.authenticated = false
				}
			}, delay) //add margin
		}

	}

	async setAccessToken(jwtResponse) {
		this.accessJWT.token = jwtResponse.token
		this.accessJWT.expires = getJWTExpiration(jwtResponse)
	}

	setAuthTTL(ttl) {

		let exp = new Date();
		let minutes = exp.getMinutes() + ttl
		let seconds = exp.getSeconds() - 30 // subtract 30' tolerance
		exp.setMinutes(minutes, seconds);
		this.authTTL = exp

	}

	async logoff(noFetch) {
		this.accessJWT.token = undefined
		this.accessJWT.expires = undefined
		localStorage.setItem('userId', null);
		if (noFetch) return
		await this.fetch("auth/logoff", {
			method: "GET",
			authorize: false
		})
	}

	async getJwt() {
		if (await this.authorize()) {
			return this.accessJWT.token
		} else {
			throw new FetchError({
				statusText: "AuthorizationError",
				status: -1,
			})
		}
	}
}

function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}

function getJWTExpiration(jwtResponse) {
	//set expiration with client time
	//this mitigates server/client time differences
	let ttl = Number(jwtResponse.ttl)
	let exp = new Date();
	let minutes = exp.getMinutes() + ttl
	let seconds = exp.getSeconds() - 30 // subtract 30' tolerance
	exp.setMinutes(minutes, seconds);
	return exp
}


export default {
	buildType: process.env.VUE_APP_BUILD,
	buildCheckInterval: 15, //minutes
	version: require('./version.json'),
	portal: {
		companyName: "Webtech",
		title: "CRM",
		apps: ["auth", "education", "customer", 'billing', 'customerServices', 'mytasks', 'config', 'contacts',],
		defaultApp: "mytasks", //default app if no userConfig
		authenticateBeforeReady: true,
		lockApp: "auth", //timeout lock app
		languages: ['de', 'fr', 'it'],
		defaultLanguage: 'de',
		api: {
			protocol: process.env.VUE_APP_API_PROTOCOL,
			server: process.env.VUE_APP_API_SERVER,
			baseUrl: "/api/",
			loginUrl: "auth/login"
		},
		loadDelay: 200, // show Delay after
		loadTimeout: 5000, // show Timeout after
		//list not supported browser
		//syntax: https://github.com/lancedikson/bowser
		browserSupport: {
			rip: {
				ie: '>1'
			},
			notSupported: null
		},
		//list browser with credential API
		//https://caniuse.com/#feat=credential-management
		//syntax: https://github.com/lancedikson/bowser
		credentialApiSupport: {
			chrome: '>=57',
			edge: '>=79',
			opera: '>=45',
			android: '>=81',
			samsung_internet: '>=7.2',
			uc: '>=12.12'
		}
	},

	debug: {
		development: {
			//level: 'DEBUG',
			//namespaces: 'DEBUG:portal,ERROR:*',
			//namespaces: 'DEBUG:portal:*',
			json: false,
			serverinfo: false,
			hideDate: false,
			colors: false,
		},
		production: {
			level: 'ERROR',
			namespaces: '*',
			json: false,
			serverinfo: false,
			hideDate: false,
			colors: true,
		}
	}
}

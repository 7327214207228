<template>
	<div class="Switch Switcher"
		:class="{ hasLabel: label }">
		<div v-if="label"
			class="label">{{ label }}</div>
		<label :class="className">
			<input type="checkbox"
				:name="name"
				:checked="value"
				:disabled="disabled"
				@change.stop="toggle" />
			<div class="rail"
				:style="railStyle">
				<div class="dot"
					:style="dotStyle" />
			</div>
			<template v-if="labels">
				<span class="label left"
					:style="labelStyle"
					v-if="toggled">
					{{ settings.labelChecked }}
				</span>
				<span class="label right"
					:style="labelStyle"
					:data-test="settings.labelUnchecked"
					v-else>
					{{ settings.labelUnChecked }}
				</span>
			</template>
		</label>
		<slot />
	</div>
</template>

<script>
//https://github.com/euvl/vue-js-toggle-button

function isString(value) {
	return typeof value === 'string'
}

function isBoolean(value) {
	return typeof value === 'boolean'
}

function isObject(value) {
	return typeof value === 'object'
}

function has(obj, key) {
	// eslint-disable-next-line no-prototype-builtins
	return isObject(obj) && obj.hasOwnProperty(key)
}

function get(object, key, defaultValue) {
	return has(object, key) ? object[key] : defaultValue
}

function px(value) {
	return `${value}px`
}

function translate(x, y) {
	return `translate(${x}, ${y})`
}
const DEFAULT_COLOR_CHECKED = '#4c668b'
const DEFAULT_COLOR_UNCHECKED = '#bfcbd9'
const DEFAULT_LABEL_CHECKED = 'on'
const DEFAULT_LABEL_UNCHECKED = 'off'
const DEFAULT_SWITCH_COLOR = '#fff'
export default {
	name: 'SwitchToggle',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		tag: {
			type: String,
			required: false,
		},
		sync: {
			type: Boolean,
			default: true,
			required: false,
		},
		speed: {
			type: Number,
			default: 300,
			required: false,
		},
		switchColor: {
			type: [String, Object],
			validator(value) {
				return isString(value) || has(value, 'checked') || has(value, 'unchecked')
			},
			required: false,
		},
		labels: {
			type: [Boolean, Object],
			default: false,
			validator(value) {
				// boolean displays labels
				// object displays labels and changes them
				return typeof value === 'object' ? value.checked || value.unchecked : typeof value === 'boolean'
			},
			required: false,
		},
		label: { required: false },
		height: {
			type: Number,
			default: 17,
		},
		width: {
			type: Number,
			default: 40,
		},
		margin: {
			type: Number,
			default: 3,
		},
		fontSize: {
			type: Number,
			required: false,
		},
		darkMode: {
			type: Boolean,
			default: false,
			required: false,
		},
		options: {
			required: false, default: () => ({})
		},
	},
	data() {
		return {
			toggled: !!this.value,
			mounted: false,
		}
	},
	methods: {
		toggle(event) {
			const toggled = !this.toggled
			if (!this.sync) {
				this.toggled = toggled
			}
			this.$emit('input', toggled)
			this.$emit('change', {
				value: toggled,
				tag: this.tag,
				srcEvent: event,
			})
		},

	},
	computed: {
		className() {
			let { toggled, disabled } = this

			return {
				toggled,
				disabled,
			}

		},
		railStyle() {
			if (this.material) {

				let backgroundColor
				if (this.darkMode) {
					backgroundColor = this.toggled ? '#e1e3e5' : this.colorCurrent + 'b3'
				} else {
					backgroundColor = this.toggled ? this.colorCurrent + 'b3' : this.settings.colorUnChecked
				}

				return {
					width: '32px',
					height: '14px',
					backgroundColor,
					borderRadius: px(Math.round(this.height / 2)),
				}
			}
			return {
				width: px(this.width),
				height: px(this.height),
				backgroundColor: this.disabled ? this.settings.colorDisabled : this.colorCurrent,
				borderRadius: px(Math.round(this.height / 2)),
			}
		},
		buttonRadius() {
			return this.height - this.margin * 2
		},
		distance() {
			return px(this.width - this.height + this.margin)
		},
		dotStyle() {
			const transition = `transform ${this.speed}ms`
			const margin = px(this.margin)
			const transform = this.toggled ? translate(this.distance, margin) : translate(margin, margin)
			const backgroundColor = this.switchColor ? this.switchColorCurrent : null
			if (this.material) {
				return {
					transition,
					transform: this.toggled ? `translate(18px, -3px)` : `translate(-6px, -3px)`,
					//backgroundColor,
					backgroundColor: this.disabled ? null : this.toggled ? this.settings.colorChecked : null,
				}
			}
			return {
				width: px(this.buttonRadius),
				height: px(this.buttonRadius),
				transition,
				transform,
				backgroundColor,
			}
		},
		labelStyle() {
			return {
				lineHeight: px(this.height),
				fontSize: this.fontSize ? px(this.fontSize) : null,
			}
		},
		colorCurrent() {
			return this.toggled ? this.settings.colorChecked : this.settings.colorUnChecked
		},
		switchColorCurrent() {
			return this.toggled ? this.settings.switchColorChecked : this.settings.switchColorUnchecked
		},
		material() {
			this.mounted
			return this.$el ? this.$el.classList.contains('material') : false
		},
		settings() {
			return {
				colorChecked: '#4c668b',
				colorUnChecked: '#9F9F9F',
				colorDisabled: '#bfcbd9',
				labelChecked: this.labels?.checked || 'on',
				labelUnChecked: this.labels?.unchecked || 'off',
				switchColorChecked: '#fff',
				switchColorUnchecked: '#fff',
				...this.options,
			}
		}
	},
	watch: {
		value(value) {
			if (this.sync) {
				this.toggled = !!value
			}
		},
	},

	mounted() {
		this.mounted = true
	},

}
</script>

<style scoped>
.Switcher {
	display: flex;
	align-items: center;
}

.Switcher.hasLabel {
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 25px;
}

.Switcher.material label {
	margin-left: 7px;
	margin-right: 7px;
}

.Switcher.material.hasLabel {
	margin-bottom: 25px;
}

.Switcher.material .label {
	margin-bottom: 11px;
}

.label {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.54);
	margin-bottom: 12px;
}

.Switcher label {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	user-select: none;
	font-size: 10px;
	cursor: pointer;
	text-align: initial;
	overflow: visible;
}

input {
	opacity: 0;
	position: absolute;
	width: 1px;
	height: 1px;
}

label .label {
	position: absolute;
	top: 0;
	font-weight: 500;
	color: white;
	z-index: 1;
}

label .label.left {
	left: 10px;
}

label .label.right {
	right: 10px;
}

label .rail {
	display: block;
	position: relative;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	transition: border-color 0.3s, background-color 0.3s;
	user-select: none;
}

label .rail .dot {
	display: block;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	border-radius: 100%;
	background-color: #fff;
	z-index: 2;
}

.Switcher.material .dot {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	background-color: #fff;
	border-color: #fff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: transform 300ms ease 0s;
	transform: translate(-1px, -1px);
}

/* .Switcher.material {
	margin: 0 5px;
} */
label.disabled {
	pointer-events: none;
	opacity: 0.6;
}
</style>

<template>
   <div class="BillView">
      <div class="header">
         <img src="@/assets/webtech_logo.svg"
            alt="Logo" />
         <div class="right">
            <div class="company">
               <div class="name">Webtech AG</div>
               <div class="street">Gitschenstrasse 9</div>
               <div class="city">6460 Altdorf</div>
            </div>
            <div class="info">
               <div class="tel">Telefon: 041 874 30 30</div>
               <div class="email">office@webtech.ch</div>
               <div class="vat">CHE-105.535.800</div>
            </div>
         </div>
      </div>
      <div class="addressRow">
         <div class="address">
            <div class="name">{{ billName }}</div>
            <div v-if="bill.customerName2"
               class="name">{{ bill.customerName2 }}</div>
            <div class="street">{{ address.street }}</div>
            <div v-if="bill.address?.street2"
               class="street">{{ address.street2 }}</div>
            <div class="city">{{ address.zip }} {{ address.city }}</div>
            <div class="email">{{ address.email }}</div>
         </div>
      </div>
      <div class="date">
         <div>Altdorf: {{ date }}</div>
      </div>
      <div class="billNo">Rechnung {{ bill.billNo }}</div>
      <template v-for="(service, index) in billingServices">
         <ProjectBilling v-if="service.type == 'project'"
            :service="service"
            :key="service._id" />
         <div v-if="service.type == 'projectEnd'"
            :key="service._id"
            class="projectEnd"></div>
         <TaskBilling v-if="service.type == 'task'"
            :service="service"
            :key="service._id"
            :style="billingServiceStyle(service, index)" />
         <FixedPriceTaskBilling v-if="service.type == 'fixedPriceTask'"
            :service="service"
            :key="service._id"
            :style="billingServiceStyle(service, index)" />
         <BchSubscriptionBilling v-if="
            service.type == 'bchSubscription'"
            :service="service"
            :key="service._id"
            :style="billingServiceStyle(service, index)" />
         <ServiceBilling v-if="service.type == 'service'"
            :service="service"
            :key="service._id"
            :style="billingServiceStyle(service, index)" />
         <BchProductBilling v-if="service.type == 'bchProduct'"
            :service="service"
            :key="service._id"
            :style="billingServiceStyle(service, index)" />
      </template>
      <div class="totalsRow">
         <div class="row">
            <div class="label">Total</div>
            <div class="currency">CHF</div>
            <div class="total">{{ total }}</div>
         </div>
         <div class="row tax"
            v-for="tax in bill.tax"
            :key="tax.tax">
            <div class="label">{{ tax.text }}</div>
            <div class="currency">CHF</div>
            <div class="total">{{ mwst(tax.value) }}</div>
         </div>
         <div class="row total">
            <div class="label">Total inkl. MWST</div>
            <div class="currency">CHF</div>
            <div class="total">{{ totalInclMwst }}</div>
         </div>
         <div class="payment">
            <div class="text">Zahlungsfrist {{ dueDate }} Netto</div>
         </div>
      </div>
      <div class="thankyouRow">
         <div class="text">
            Danke an unsere grossartigen Webtech Kundinnen und Kunden! Ihr seid der Grund, warum wir existieren und
            weiterhin tolle digitale Produkte entwickeln können.
         </div>
      </div>
      <QrCode :amount="bill.value"
         :customerId="bill.customerId"
         :billNo="bill.billNo"
         :customerName="billName"
         :customerAddress="bill.address" />
   </div>
</template>

<script>
import ProjectBilling from '../project/ProjectBilling.vue'
import TaskBilling from '../task/TaskBilling.vue'
import FixedPriceTaskBilling from '../fixedPriceTask/FixedPriceTaskBilling.vue'
import BchSubscriptionBilling from '../bch/BchSubscriptionBilling.vue'
import BchProductBilling from '../bchProduct/BchProductBilling.vue'
import ServiceBilling from '../service/ServiceBilling.vue'
import { Day } from "@SyoLab/date-time"
import Button from '@components/Form/Button.vue'
import QrCode from './QrCode.vue'

// a 4 pixel: width:794px; height:1122px 96 DPI
// or 3300px; width: 2550px. 300dpi
export default {
   name: 'BillView',
   components: { ProjectBilling, TaskBilling, Button, QrCode, BchSubscriptionBilling, FixedPriceTaskBilling, ServiceBilling, BchProductBilling },
   props: {
      bill: { required: true },
   },
   data() {
      return {
      }
   },
   methods: {
      mwst(tax) {
         return Number(tax).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      billingServiceStyle(service, index) {
         let style = {}
         if (index > 0) {
            let prevService = this.bill.services[index - 1]
            if (prevService.type != 'project') {
               style.breakInside = 'avoid'
               style.pageBreakInside = 'avoid'
            }
         }
         return style
      }
   },
   computed: {
      billingServices() {
         let services = []
         if (Array.isArray(this.bill.services)) {
            this.bill.services.forEach((service, index) => {
               if (index > 0) {
                  let prevService = this.bill.services[index - 1]
                  console.log('prevService', prevService.project_id, service.project_id)
                  if (prevService.project_id != service.project_id) {
                     console.log('endProject', index)
                     services.push({ type: 'projectEnd', _id: String(index) })
                  }

               }
               services.push(service)
            })
         }
         return services
      },
      total() {
         return Number(this.bill.servicesValue).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      totalInclMwst() {
         return Number(this.bill.value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      date() {
         return new Day(this.bill.date).toEuDate()
      },
      dueDate() {
         return new Day(this.bill.dueDate).toEuDate()
      },
      address() {
         return this.bill.address || {}
      },
      billName() {
         let name = this.bill.customerName
         if (this.bill.customerFirstName) {
            name = `${this.bill.customerFirstName} ${this.bill.customerName}`
         }
         return name
      }

   },

}
</script>

<style scoped>
.BillView {
   width: 1060px;
   display: flex;
   flex-direction: column;
   padding: 55px;
   padding-left: 105px;
   font-size: 18px;
}


.totalsRow {
   display: flex;
   flex-direction: column;
   padding: 15px 0;
   page-break-inside: avoid;
   break-inside: avoid
}

.totalsRow .row {
   justify-content: flex-end;
}

.totalsRow .row .label {
   margin-right: 12px;
}

.totalsRow .row .currency {
   margin-right: 10px;
   width: 38px;
}

.totalsRow .row .total {
   margin-left: 10px;
   width: 71px;
   text-align: right;
}

.totalsRow .row.total {
   margin-top: 15px;
   font-weight: 700;
   padding-top: 15px;
}

.totalsRow .payment {
   font-size: 12px;
   display: flex;
   justify-content: flex-end;
   padding-top: 15px;
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.header>img {
   width: 250px;
}

.header .right {
   display: flex;
   column-gap: 35px;
}

.header .company,
.header .info {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.header .company .name {
   font-weight: bold;
}

.addressRow {
   display: flex;
   min-height: 260px;
}

.addressRow .address {
   margin-top: 125px;
   display: flex;
   flex-direction: column;
}

.address .email {
   margin-top: 25px;
}

.date {
   margin-top: 35px;
   display: flex;
   justify-content: flex-start;
}

.billNo {
   margin-top: 15px;
   display: flex;
   justify-content: flex-start;
   font-size: 18px;
   font-weight: 700;
   margin-bottom: 15px;
}

.thankyouRow {
   padding-top: 25px;
   padding-bottom: 35px;
   font-size: 12px;
   display: flex;
   justify-content: flex-start;
   page-break-inside: avoid;
   break-inside: avoid
}

.QrCode {
   page-break-inside: avoid;
   break-inside: avoid
}

.projectEnd {
   width: 100%;
   height: 10px;
   border-top: 1px solid grey;
}

@page {
   size: A4;
   margin: 0;
   margin-top: 75px;
}

@page :first {
   margin-top: 0;
}
</style>
<template>
   <div class="QrCode">
      <div class="qrCode"
         ref=qrCode></div>
      <div v-if="qrShow"
         class="column">
         <div class="company">
            <div class="title">Zahlbar an</div>
            <div class="account">{{ creditor.account }}</div>
            <div class="name">{{ creditor.name }}</div>
            <div class="row">
               <div class="address">{{ creditor.address }}</div>
               <div class="buildingNumber">{{ creditor.buildingNumber }}</div>
            </div>
            <div class="row">
               <div class="zip">{{ creditor.zip }}</div>
               <div class="city">{{ creditor.city }}</div>
            </div>
         </div>
         <div class="reference">
            <div class="title">Referenz</div>
            <div class="amount">{{ qrData.reference }}</div>
            <div class="message">{{ qrData.message }}</div>
         </div>
      </div>
   </div>
</template>

<script>
import { SwissQRBill, SwissQRCode } from "swissqrbill/svg"
export default {
   name: 'QrCode',
   components: {},
   props: {
      amount: { required: true },
      customerName: { required: true },
      customerAddress: { required: true },
      message: { required: false },
      customerId: { required: true },
      billNo: { required: true },
   },
   data() {
      return {
         creditor: {
            account: "CH11 3078 5001 2239 3029 4",
            address: "Gitschenstrasse",
            buildingNumber: 9,
            city: "Altdorf",
            country: "CH",
            name: "Webtech AG",
            zip: '6460'
         },
         esrIdentNo: '70391',
         mounted: false
      }
   },
   methods: {
      async qrGenerate() {
         if (!this.qrShow) {
            console.log('qrShow is false', this.qrShow)
            return
         }

         if (!this.$refs || !this.$refs.qrCode) {
            console.log('no qrCode $ref', this.$refs.qrCode)
            return
         }

         if (this.$refs.qrCode.firstChild) {
            this.$refs.qrCode.firstChild.remove()
         }

         const qrCode = new SwissQRCode(this.qrData)
         console.log('qrCode', qrCode)
         this.$refs.qrCode.appendChild(qrCode.element)
      }

   },
   computed: {
      qrData() {
         return {
            amount: this.amount,
            creditor: {
               ...this.creditor
            },
            currency: "CHF",
            debtor: {
               name: this.customerName || '',
               address: this.customerAddress?.street || '',
               zip: this.customerAddress?.zip || '',
               city: this.customerAddress?.city || '',
               country: this.customerAddress?.country || "CH",
            },
            message: this.message || '',
            reference: this.esrReferenceWithChecksum
         }
      },
      qrShow() {
         if (!this.amount) return false
         if (!this.customerId) return false
         if (!this.billNo) return false
         return true
      },
      esrReferenceWithChecksum() {

         // add the customer id to the reference number
         let refNo = `${this.esrIdentNo}00${this.customerId}`
         // fill up the reference number with zeros
         refNo = refNo.padEnd(26 - String(this.billNo).length, "0")
         // add the bill number to the reference number
         refNo += String(this.billNo)

         // vorgebener Algorithmus in Tabellenform
         let alg = new Array(0, 9, 4, 6, 8, 2, 7, 1, 3, 5);

         // calculate the checksum
         let remainder = 0
         for (let i = 0; i < refNo.length; i++) {
            let test = parseInt(remainder) + parseInt(refNo[i])
            remainder = alg[test % 10]
         }
         let esrCheckNo = (10 - remainder) % 10

         // nicely space out the reference number
         return refNo[0] + refNo[1] + " " +
            refNo[2] + refNo[3] + refNo[4] + refNo[5] + refNo[6] + " " +
            refNo[7] + refNo[8] + refNo[9] + refNo[10] + refNo[11] + " " +
            refNo[12] + refNo[13] + refNo[14] + refNo[15] + refNo[16] + " " +
            refNo[17] + refNo[18] + refNo[19] + refNo[20] + refNo[21] + " " +
            refNo[22] + refNo[23] + refNo[24] + refNo[25] + esrCheckNo
      }
   },
   watch: {
      qrData: {
         deep: true,
         handler() {
            this.qrGenerate()
         }
      }
   },
   mounted() {
      this.qrGenerate()
   }
}
</script>

<style scoped>
.QrCode {
   display: flex;
   column-gap: 55px;
}

.column {
   display: flex;
   flex-direction: column;
   row-gap: 10px;

}

.company {
   display: flex;
   flex-direction: column;
}

.company .title,
.payment .title,
.reference .title {
   font-size: 14px;
   font-weight: 700;
}

.company .row {
   column-gap: 5px;
}

.payment .gracePeriod {
   font-size: 12px;
}
</style>